import { conditionalClasses } from '@/utils/tailwind';
import { ArrowPathIcon, ArrowUturnLeftIcon, ArrowUturnRightIcon, Bars3BottomLeftIcon, Bars3BottomRightIcon, BoldIcon, H1Icon, H2Icon, H3Icon, ItalicIcon, ListBulletIcon, NumberedListIcon, StrikethroughIcon, UnderlineIcon } from '@heroicons/react/20/solid';
import { type Command, type Editor, Extension } from '@tiptap/core';
import ListItem from '@tiptap/extension-list-item';
const TAB_CHAR = '\u0009';
declare module '@tiptap/core' {
  interface Commands {
    indent: {
      // Set the indent attribute
      indent: () => Command;
      // Unset the indent attribute
      outdent: () => Command;
    };
  }
}
export const IndentExtension = Extension.create({
  name: 'indent',
  addCommands() {
    return {
      indent: () => ({
        editor
      }) => {
        const {
          selection
        } = editor.state;
        const {
          $from
        } = selection;

        // Check if we're at the start of a list item
        if (editor.isActive('listItem') && $from.parentOffset === 0) {
          // Attempt to sink the list item
          const sinkResult = editor.chain().sinkListItem('listItem').run();

          // If sinking was successful, return true
          if (sinkResult) {
            return true;
          }
          // If sinking failed, we'll fall through to inserting a tab
        }

        // Insert a tab character
        editor.chain().command(({
          tr
        }) => {
          tr.insertText(TAB_CHAR);
          return true;
        }).run();

        // Prevent default behavior (losing focus)
        return true;
      },
      outdent: () => ({
        editor
      }) => {
        const {
          selection,
          doc
        } = editor.state;
        const {
          $from
        } = selection;
        const pos = $from.pos;

        // Check if we're at the start of a list item
        if (editor.isActive('listItem') && $from.parentOffset === 0) {
          // If so, lift the list item
          return editor.chain().liftListItem('listItem').run();
        }

        // Check if the previous character is a tab
        if (doc.textBetween(pos - 1, pos) === TAB_CHAR) {
          // If so, delete it
          editor.chain().command(({
            tr
          }) => {
            tr.delete(pos - 1, pos);
            return true;
          }).run();
          return true;
        }

        // Prevent default behavior (losing focus)
        return true;
      }
    };
  }
});
export const CustomListItem = ListItem.extend({
  addAttributes() {
    return {
      class: {
        default: null,
        // change this if you want a different default value
        parseHTML: (element: HTMLElement) => element.classList.contains('ql-indent-1') ? 'ql-indent-1' : null
      }
    };
  }
});
export function EditorMenuBar({
  editor
}: {
  editor: Editor | null;
}) {
  const buttonClasses = 'p-2';
  const iconClasses = (isActive: boolean) => {
    return conditionalClasses(isActive ? 'text-podi-primary' : 'text-gray-900', 'h-4');
  };
  if (!editor) {
    return null;
  } else {
    return <div className="flex flex-row items-center justify-start gap-4 rounded-t-md border-l border-r border-t border-gray-300 px-3 py-1">
        <div>
          <button onClick={() => editor.chain().focus().toggleHeading({
          level: 1
        }).run()} className={buttonClasses}>
            <H1Icon className={iconClasses(editor.isActive('heading', {
            level: 1
          }))} />
          </button>
          <button onClick={() => editor.chain().focus().toggleHeading({
          level: 2
        }).run()} className={buttonClasses}>
            <H2Icon className={iconClasses(editor.isActive('heading', {
            level: 2
          }))} />
          </button>
          <button onClick={() => editor.chain().focus().toggleHeading({
          level: 3
        }).run()} className={buttonClasses}>
            <H3Icon className={iconClasses(editor.isActive('heading', {
            level: 3
          }))} />
          </button>
        </div>
        <div>
          <button onClick={() => editor.chain().focus().toggleBold().run()} disabled={!editor.can().chain().focus().toggleBold().run()} className={buttonClasses}>
            <BoldIcon className={iconClasses(editor.isActive('bold'))} />
          </button>
          <button onClick={() => editor.chain().focus().toggleItalic().run()} disabled={!editor.can().chain().focus().toggleItalic().run()} className={buttonClasses}>
            <ItalicIcon className={iconClasses(editor.isActive('italic'))} />
          </button>
          <button onClick={() => editor.chain().focus().toggleUnderline().run()} disabled={!editor.can().chain().focus().toggleUnderline().run()} className={buttonClasses}>
            <UnderlineIcon className={iconClasses(editor.isActive('underline'))} />
          </button>
          <button onClick={() => editor.chain().focus().toggleStrike().run()} disabled={!editor.can().chain().focus().toggleStrike().run()} className={buttonClasses}>
            <StrikethroughIcon className={iconClasses(editor.isActive('strike'))} />
          </button>
          <button onClick={() => editor.chain().focus().unsetAllMarks().run()} disabled={!editor.can().chain().focus().unsetAllMarks().run()} className={buttonClasses}>
            <ArrowPathIcon className={iconClasses(false)} />
          </button>
        </div>
        <div>
          <button onClick={() => editor.chain().focus().toggleOrderedList().run()} className={buttonClasses}>
            <NumberedListIcon className={iconClasses(editor.isActive('orderedList'))} />
          </button>
          <button onClick={() => editor.chain().focus().toggleBulletList().run()} className={buttonClasses}>
            <ListBulletIcon className={iconClasses(editor.isActive('bulletList'))} />
          </button>
          <button onClick={() => editor.chain().focus().outdent()} disabled={!(editor.isActive('bulletList') || editor.isActive('orderedList'))} className={buttonClasses}>
            <Bars3BottomLeftIcon className={iconClasses(false)} />
          </button>
          <button onClick={() => editor.chain().focus().indent()} className={buttonClasses} disabled={!(editor.isActive('bulletList') || editor.isActive('orderedList'))}>
            <Bars3BottomRightIcon className={iconClasses(false)} />
          </button>
        </div>
        <div>
          <button onClick={() => editor.chain().focus().undo().run()} disabled={!editor.can().chain().focus().undo().run()} className={buttonClasses}>
            <ArrowUturnLeftIcon className={iconClasses(false)} />
          </button>
          <button onClick={() => editor.chain().focus().redo().run()} disabled={!editor.can().chain().focus().redo().run()} className={buttonClasses}>
            <ArrowUturnRightIcon className={iconClasses(false)} />
          </button>
        </div>
      </div>;
  }
}