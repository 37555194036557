import { conditionalClasses } from '@/utils/tailwind';
import { Listbox, Transition, type ListboxProps } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { type FieldError } from 'react-hook-form';
import { Avatar } from './Avatar';

// a helper method so that if we have a list of values, we can convert them to options
// e.g. <Select options={convertValuesToOptions([1, 2, 3])} /> .. would be the same as
//      <Select options={[{ value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' }]} />

export const convertValuesToOptions = (values: SelectValue[] | readonly SelectValue[]): SelectOption[] => values.map(value => ({
  value,
  label: String(value ?? '')
}));
export type SelectValue = number | string | boolean | null;
export interface SelectOption<T = SelectValue> {
  value: T;
  label: string;
  subLabel?: string;
  image?: string; // not in use yet, but if we want to add an image or something else to the option, could do here
  disabled?: boolean;
}
interface Props<T = SelectValue> extends ListboxProps<'div', T, T> {
  options: SelectOption<T>[];
  placeholder?: string;
  label?: string;
  icon?: JSX.Element;
  required?: boolean;
  showImage?: boolean;
  fieldError?: FieldError;
  className?: string;
  containerClassName?: string;
  buttonClassName?: string;
  optionsClassName?: string;
}
export function Select({
  value = '',
  onChange,
  options,
  disabled,
  placeholder,
  label,
  icon,
  required = false,
  showImage = false,
  fieldError,
  className,
  containerClassName,
  buttonClassName,
  optionsClassName,
  ...listboxProps
}: Props) {
  if (!placeholder) placeholder = `Select ${label || 'Option'}`;
  const getActiveOption = () => options.find(option => option.value === value);
  return <div className={className} data-sentry-component="Select" data-sentry-source-file="Select.tsx">
      <Listbox as="div" value={value} onChange={onChange} {...listboxProps} data-sentry-element="Listbox" data-sentry-source-file="Select.tsx">
        {({
        open
      }) => <div className={containerClassName}>
            {label && <Listbox.Label as="label" className="mb-2 block text-sm font-medium leading-3 text-gray-900">
                {label}
                {required && ' *'}
              </Listbox.Label>}
            <div className="relative">
              <Listbox.Button as="button" className={conditionalClasses('relative w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-gray-900 focus:outline-none focus:ring-2 focus:ring-podi-primary sm:text-sm sm:leading-6', {
            'focus-ring-0 pointer-events-none bg-gray-50 shadow-none ring-0': disabled,
            'pl-10': icon // Add padding to prevent overlap when icon is used
          }, buttonClassName)}>
                <span className="block min-h-6">{getActiveOption()?.label || placeholder}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className={conditionalClasses('h-5 w-5 text-base', {
                'text-gray-400': disabled
              })} aria-hidden="true" />
                </span>
                {icon && <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">{icon}</div>}
              </Listbox.Button>

              <Transition show={!disabled && open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Listbox.Options as="div" className={conditionalClasses(`absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`, optionsClassName)}>
                  {(options || []).map(option => <Listbox.Option as="div" key={`option_${option.value}`} className={({
                active,
                disabled
              }) => conditionalClasses(active ? 'cursor-pointer bg-podi-primary text-white' : disabled ? 'cursor-not-allowed text-slate-400' : 'text-gray-900', 'relative select-none px-3 py-2')} value={option.value} disabled={option.disabled}>
                      {({
                  selected,
                  active
                }) => <div className="flex flex-row items-center justify-between ">
                          <div className="flex flex-row justify-start gap-2">
                            {showImage && <Avatar className="h-8 w-8" image={option.image} name={option?.label || ''} />}
                            <span className={conditionalClasses(selected ? 'font-semibold' : 'font-normal', 'block')}>{option.label}</span>
                          </div>
                          {selected ? <span className={conditionalClasses(active ? 'text-white' : 'text-podi-primary')}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span> : null}
                        </div>}
                    </Listbox.Option>)}
                </Listbox.Options>
              </Transition>
            </div>
          </div>}
      </Listbox>
      {fieldError && <p className="p-1 text-xs text-red-500">{fieldError.message}</p>}
    </div>;
}