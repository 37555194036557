import { conditionalClasses } from '@/utils/tailwind';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useCallback, type Dispatch, type SetStateAction } from 'react';
import { useController, type FieldValues, type UseControllerProps } from 'react-hook-form';
interface ControlledSelectInputProps<T, K extends keyof T> {
  options: T[];
  containerClassName?: string;
  name: string;
  formId?: string;
  loading?: boolean;
  forBoolean?: boolean;
  formValueKey: K;
  stateValue: T | undefined;
  inputClassName?: string;
  buttonClassName?: string;
  label?: string;
  listUp?: boolean;
  setStateValue: Dispatch<SetStateAction<T>> | Dispatch<SetStateAction<T | undefined>>;
}
type ControllerProps<C extends FieldValues> = UseControllerProps<C>;

/**
 * ControlledComboBox used with React Hook Forms (RHF)
 * @example
 * name: name of form input used by RHF
 * formId: html #id
 * stateValue, setStateValue: external form state
 * formValueKey: options key used to get input value that is tested and displayed in input
 * control: control object from useForm
 */

export function ControlledSelectInput<T, K extends keyof T, C extends FieldValues>(props: ControllerProps<C> & ControlledSelectInputProps<T, K>) {
  const {
    containerClassName,
    formId,
    options,
    forBoolean,
    formValueKey,
    stateValue,
    inputClassName,
    buttonClassName,
    label,
    loading,
    listUp,
    setStateValue,
    ...controllerProps
  } = props;
  const disabled = controllerProps?.disabled;
  const required = controllerProps?.rules?.required;
  const {
    field,
    fieldState
  } = useController(controllerProps);
  const parsedOptions = useCallback((option: T) => {
    if (typeof option === 'object' && option !== null && formValueKey in option) {
      const optionVal = option[formValueKey] as string | number;
      return optionVal;
    }
    return '';
  }, [formValueKey]);
  return <>
      <Listbox as="div" value={stateValue} onChange={option => {
      field.onChange(option); // data send back to hook form
      if (setStateValue) setStateValue(option); // UI state
    }} data-sentry-element="Listbox" data-sentry-source-file="ControlledSelectInput.tsx">
        {({
        open
      }) => <div className={containerClassName}>
            {label && <Listbox.Label as="label" className="mb-2 block text-sm font-medium leading-3 text-gray-900">
                {label}
                {required && ' *'}
              </Listbox.Label>}
            <div className="relative">
              <Listbox.Button as="button" className={conditionalClasses('relative h-10 w-full cursor-pointer rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 focus:outline-none focus:ring-2 focus:ring-podi-primary sm:text-sm sm:leading-6', {
            'focus-ring-0 pointer-events-none bg-gray-50 shadow-none ring-0': disabled || loading
          }, {
            'border-red-500 focus:border-red-500 focus:ring-red-500': fieldState?.invalid
          }, buttonClassName)}>
                {stateValue ? stateValue[formValueKey] as string : ''}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className={conditionalClasses('h-5 w-5 text-base', {
                'text-gray-400': disabled || loading
              })} aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition show={!disabled && !loading && open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Listbox.Options as="div" className={`absolute ${listUp && 'bottom-11'} z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}>
                  {options?.map((option, index: number) => <Listbox.Option as="div" key={index} className={({
                active
              }) => conditionalClasses(active ? 'bg-podi-primary text-white' : 'text-gray-900', 'relative cursor-pointer select-none py-2 pl-3 pr-9')} value={option}>
                      {({
                  selected,
                  active
                }) => <>
                          <span className={conditionalClasses(selected ? 'font-semibold' : 'font-normal', 'block')}>{parsedOptions(option)}</span>

                          {selected ? <span className={conditionalClasses(active ? 'text-white' : 'text-podi-primary', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span> : null}
                        </>}
                    </Listbox.Option>)}
                </Listbox.Options>
              </Transition>
            </div>
          </div>}
      </Listbox>
      {fieldState?.error && <p className="ml-2 mt-4 text-sm text-red-600" id="email-error">
          {fieldState?.error.message || 'Please select a value.'}
        </p>}
    </>;
}