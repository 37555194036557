import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { EditorContent, type Extensions, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect } from 'react';
import { type FieldError } from 'react-hook-form';
import { CustomListItem, EditorMenuBar, IndentExtension } from './Editor.utils';
import { FormLabel } from './FormLabel';
interface Props {
  disabled?: boolean;
  fieldError?: FieldError;
  label?: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  placeholder?: string;
  required?: boolean;
  value: string;
  className?: string;
}
export function Editor({
  disabled,
  fieldError,
  label,
  onBlur = () => null,
  onChange,
  placeholder = '',
  required,
  value,
  className
}: Props) {
  const extensions: Extensions = [IndentExtension, Placeholder.configure({
    placeholder,
    showOnlyWhenEditable: false
  }), StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false // per examples
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false // per examples
    },
    listItem: false // using CustomListItem (below - with legacy .ql-indent-[n] support)
  }), CustomListItem, Underline];
  const editor = useEditor({
    content: value || '',
    editable: !disabled,
    editorProps: {
      attributes: {
        class: `${disabled ? 'rounded-md bg-gray-50' : 'rounded-b-md bg-white'}  border p-3 border-gray-300 text-gray-900 m-0 placeholder-gray-400 focus:outline-none disabled:pointer-events-none text-sm leading-6 [&_h1]:text-xl [&_h2]:text-lg [&_h3]:text-md [&_p]:m-0 [&_p]:text-sm [&_p]:leading-6 [&_p]:font-normal [&_ul]:list-disc [&_ul]:pl-6 [&_ul]:leading-6 [&_ol]:list-decimal [&_ol]:pl-6 [&_ol]:leading-6 [&_li]:ml-6 [&_p]:!m-0 [&_p]:!text-sm [&_p]:!leading-6 [&_p]:!font-normal min-h-[10rem]`
      }
    },
    extensions,
    immediatelyRender: true,
    parseOptions: {
      preserveWhitespace: 'full'
    },
    onBlur,
    onUpdate({
      editor
    }) {
      const _value = editor.getHTML();
      onChange(_value);
    }
  });
  useEffect(() => {
    if (editor?.isEmpty || value !== editor?.getHTML()) editor.commands.setContent(value || '', false, {
      preserveWhitespace: 'full'
    });
  }, [value, editor]);
  useEffect(() => {
    if (!editor) return;
    editor.setEditable(!disabled);
  }, [editor, disabled]);
  return <div className={className} data-sentry-component="Editor" data-sentry-source-file="Editor.tsx">
      {label && <FormLabel>
          {label}
          {required ? ' *' : ''}
        </FormLabel>}
      {!disabled && <EditorMenuBar editor={editor} />}
      <EditorContent editor={editor} data-sentry-element="EditorContent" data-sentry-source-file="Editor.tsx" />
      {fieldError && <p className="p-1 text-xs text-red-500">{fieldError.message}</p>}
    </div>;
}