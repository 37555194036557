import { type FuseOptions, useFuse } from '@/hooks/useFuse';
import { type QueryKeyBase } from '@/utils/react-query';
import { JSONSafeParse } from '@/utils/string';
import { conditionalClasses } from '@/utils/tailwind';
import { type ColumnDef, getCoreRowModel, type PaginationState, type SortingState, useReactTable } from '@tanstack/react-table';
import { debounce, orderBy } from 'lodash';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';
import { evalInitialTableParam, SearchInputMemo, TableEmpty, TableFooter, TableHeader, TableLoaderProgressBar, TableLoaderSkeleton, type TableParams, TableRows } from './Table.utils';

/* --------------------------------- premise -------------------------------- */
// Pass in full dataset as "data" prop and handle the search and pagination on the client side

/* ---------------------------------- usage --------------------------------- */
// <TableClientside data={customers} columns={columns} />

export interface TableClientsideProps<T = any> {
  queryKeyBase?: QueryKeyBase;
  data?: T[];
  title?: string;
  columns: ColumnDef<T>[];
  sorting?: SortingState;
  pagination?: PaginationState;
  search?: string;
  accessories?: JSX.Element;
  className?: string;
  rowClassesFn?: (item: T) => string;
  saveStateInUrl?: boolean;
  isSearchable?: boolean;
  disablePageSizeSelect?: boolean;
  fuseOptions?: FuseOptions<T>;
}
export function TableClientside({
  queryKeyBase,
  data: _data,
  title,
  columns,
  sorting: _sorting = [],
  pagination: _pagination = {
    pageIndex: 0,
    pageSize: 10
  },
  search: _search = '',
  accessories,
  className = '',
  rowClassesFn,
  saveStateInUrl = false,
  isSearchable = true,
  disablePageSizeSelect = false,
  fuseOptions = {}
}: TableClientsideProps) {
  const tableKey = queryKeyBase ?? (title ? `${title?.replace(/\s/g, '')?.toLowerCase()}Table` : 'table');
  const router = useRouter();
  const tableSearchParamsJSON = useSearchParams().get(tableKey);
  const tableSearchParams = tableSearchParamsJSON ? JSONSafeParse<TableParams>(tableSearchParamsJSON) : {} as TableParams;
  const [sorting, setSorting] = useState<SortingState>(evalInitialTableParam(_sorting, tableSearchParams?.SortBy ? [{
    id: tableSearchParams.SortBy,
    desc: tableSearchParams.SortDesc
  }] : undefined) as SortingState);
  const [pagination, setPagination] = useState<PaginationState>(evalInitialTableParam(_pagination, tableSearchParams?.ItemsPerPage ? {
    pageIndex: tableSearchParams.Page,
    pageSize: tableSearchParams.ItemsPerPage
  } : undefined) as PaginationState);
  const {
    hits,
    onSearch,
    query
  } = useFuse<(typeof _data)[]>(_data || [], {
    isCaseSensitive: false,
    includeScore: true,
    includeMatches: true,
    matchAllOnEmptyQuery: true,
    threshold: 0.3,
    keys: columns.filter(column => !!(column as any)?.accessorKey).map(column => (column as any).accessorKey),
    ...fuseOptions
  }, evalInitialTableParam(_search, tableSearchParams?.Search) as string || '');
  const debouncedOnSearch = debounce(onSearch, 500);
  const tableParams: TableParams = {
    ItemsPerPage: pagination.pageSize,
    Page: pagination.pageIndex,
    Search: query,
    SortBy: sorting?.[0]?.id ?? null,
    SortDesc: sorting?.[0]?.desc ?? true
  };
  useEffect(() => {
    if (!saveStateInUrl) return;
    const existingParams = Object.fromEntries(new URLSearchParams(location.search));
    const newParams = {
      [tableKey]: JSON.stringify(tableParams)
    };
    const params = new URLSearchParams({
      ...existingParams,
      ...newParams
    });
    router.push(`${window.location.pathname}?${params}`);
  }, [tableParams.ItemsPerPage, tableParams.Page, tableParams.Search, tableParams.SortBy, tableParams.SortDesc]);
  const filteredData = useMemo(() => {
    const pageSize = pagination.pageSize;
    const skipSize = pagination.pageIndex * pageSize;
    return orderBy<TableClientsideProps['data']>(hits.map(({
      item
    }) => item), sorting?.[0]?.id || 'id', sorting?.[0]?.desc ?? true ? 'desc' : 'asc').slice(skipSize, skipSize + pageSize);
  }, [hits, sorting, pagination]);
  const table = useReactTable({
    columns,
    data: filteredData ?? [],
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: _data?.length,
    state: {
      sorting,
      pagination
    }
  });
  return <div className={conditionalClasses('flex flex-col gap-4 rounded-lg border', className)} data-sentry-component="TableClientside" data-sentry-source-file="TableClientside.tsx">
      <header className="flex flex-row items-center justify-between p-4">
        <span>{title && <h3 className="text-lg font-bold">{title}</h3>}</span>
        <div className="flex flex-row items-center justify-between gap-2">
          {!!accessories && accessories}
          {isSearchable && <SearchInputMemo query={query} onChange={value => debouncedOnSearch(value)} />}
        </div>
      </header>
      <table className="relative min-w-full divide-y divide-gray-200 overflow-hidden">
        <TableHeader headerGroups={table.getHeaderGroups()} data-sentry-element="TableHeader" data-sentry-source-file="TableClientside.tsx" />
        {!_data ? <>
            <TableLoaderProgressBar />
            <TableLoaderSkeleton pageSize={table.getState().pagination.pageSize} colSize={columns.length} />
          </> : <tbody className="bg-white">
            {filteredData.length === 0 && <TableEmpty colSize={columns.length} />}
            <TableRows rows={table.getRowModel().rows} rowClassesFn={rowClassesFn} />
          </tbody>}
      </table>
      <TableFooter table={table} disablePageSizeSelect={disablePageSizeSelect} data-sentry-element="TableFooter" data-sentry-source-file="TableClientside.tsx" />
    </div>;
}