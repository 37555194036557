import { conditionalClasses } from '@/utils/tailwind';
import { useEffect, useRef, useState, type FC } from 'react';
export const MultiSelectDropdown: FC<any> = ({
  options: values,
  initals,
  action,
  fieldName,
  label,
  className,
  labelClassName,
  disabled
}) => {
  const [options] = useState<any>(values || {});
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<any>(initals || []);
  const dropdownRef = useRef<any>(null);
  const handleCheckboxChange = (option: any) => {
    if (selectedOptions.includes(option.label)) {
      setSelectedOptions(selectedOptions.filter((id: any) => id !== option.label));
    } else {
      setSelectedOptions([...selectedOptions, option.label]);
    }
  };
  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (action) {
      action(fieldName, selectedOptions);
    }
  }, [selectedOptions]);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return <div className={conditionalClasses(className)} ref={dropdownRef} data-sentry-component="MultiSelectDropdown" data-sentry-source-file="MultiSelectDropdown.tsx">
      <label className={`block text-sm font-medium text-gray-700 ${labelClassName}`}>{label}</label>
      <div className="relative mt-1">
        <div className="flex items-center">
          <div className="relative inline-block w-full text-left">
            <span className="rounded-md shadow-sm">
              <button type="button" className={`and focus:shadow-outline-blue inline-flex h-auto min-h-10 !w-full overflow-hidden overflow-y-auto rounded-md border border-gray-300 px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800 ${disabled ? 'cursor-not-allowed bg-gray-100/50' : 'bg-white'}`} onClick={handleDropdownToggle} aria-haspopup="true" aria-expanded={isOpen} disabled={disabled}>
                {selectedOptions?.join(', ')}
              </button>
            </span>
          </div>
        </div>
        {isOpen && <div className={conditionalClasses('absolute mt-1 w-56 origin-top rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5')} role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <div style={{
          maxHeight: '400px'
        }} className="absolute z-10 w-full overflow-y-auto rounded-lg border border-gray-300 bg-white py-1 shadow-lg">
              {options.map((option: any) => <div key={option.label} className="flex items-center px-4 py-2">
                  <input id={`option-${option.label}`} type="checkbox" className="form-checkbox h-5 w-5 rounded-md border-gray-300 text-indigo-600 transition duration-150 ease-in-out" checked={selectedOptions.includes(option.label)} onChange={() => handleCheckboxChange(option)} />
                  <label htmlFor={`option-${option.label}`} className="ml-3 block text-sm leading-5 text-gray-700">
                    {option.label}
                  </label>
                </div>)}
            </div>
          </div>}
      </div>
    </div>;
};