import { conditionalClasses } from '@/utils/tailwind';
import type { FC } from 'react';
import { Tooltip as TooltipBoxItem, type PlacesType } from 'react-tooltip';
interface TooltipBoxProps {
  targetElementId: string;
  children: React.ReactNode;
  placement: PlacesType | undefined;
}
export const TooltipBox: React.FC<TooltipBoxProps> = ({
  targetElementId,
  children,
  placement
}) => {
  return <TooltipBoxItem openEvents={{
    mouseenter: true
  }} className="z-50 max-w-xl text-center" anchorSelect={targetElementId} place={placement} data-sentry-element="TooltipBoxItem" data-sentry-component="TooltipBox" data-sentry-source-file="Tooltip.tsx">
      {children}
    </TooltipBoxItem>;
};
interface TooltipProps {
  children?: React.ReactNode; //children component (owner of the tooltip)
  text?: string; //text to add to the tooltip
  className?: string; //can edit tootip attributes
  arrowClassName?: string; //can edit tootip arrow attributes
  containerClassName?: string; //can edit tootip container attributes
  component?: React.ReactNode; //component class to add inside of the tooltip
  onClick?: () => void; //onClick function on element
}
export const Tooltip: FC<TooltipProps> = ({
  className,
  text,
  containerClassName,
  arrowClassName,
  children,
  component,
  onClick,
  ...props
}) => {
  return <div className={conditionalClasses('bg-transparent', className)} onClick={onClick} data-sentry-component="Tooltip" data-sentry-source-file="Tooltip.tsx">
      <div className="group">
        {children}
        <div className={conditionalClasses('z-1000 pointer-events-none invisible absolute items-center opacity-0 duration-200 group-hover:visible group-hover:opacity-100 group-hover:duration-300 group-hover:ease-in-out', containerClassName)}>
          <div className={conditionalClasses('ml-3 w-1 border-x-8 border-b-8 border-t-0 border-solid border-podi-primary border-x-transparent border-opacity-100 shadow-md', arrowClassName)}></div>
          <div className={conditionalClasses('tooltip-item ml-1 flex flex-col rounded-md bg-podi-primary bg-opacity-100 px-4 py-2 text-sm font-semibold normal-case tracking-normal text-white shadow-md')} {...props}>
            {text}
            {component}
          </div>
        </div>
      </div>
    </div>;
};